<template>
  <div>
    <div v-if="currentContents.length > 0">
      <vx-card class="mb-base">
        <div class="vx-row mb-5 flex">
          <div class="vx-col md:w-1/2 w-full">
            <h2 class="mb-0">Current Subscription Details</h2>
          </div>
          <div class="vx-col md:w-1/2 w-full mt-5 md:mt-0 flex">
            <!--            <vs-button color="danger" @click="cancelHandler" class="md:ml-auto">
                          Cancel Subscription
                        </vs-button>-->
            <vs-button color="primary" @click="editSubscriptionHandler" class="md:ml-auto">
              Edit
            </vs-button>
          </div>
        </div>
        <vs-row
          v-if="currentContents"
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            class="w-full sm:w-1/2 pr-5 mt-5"
          >
            <div>
              <label class="font-bold">Subscription Name:</label>
              <p>
                {{
                  currentContents[0].subscriptionPackageDetails[0].name
                    | capitalize
                }}
              </p>
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            class="w-full sm:w-1/2 pl-5 mt-5"
          >
            <div>
              <label class="font-bold">Price:</label>
              <p>${{ currentContents[0].packageAmount }} /child/month</p>
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            class="w-full sm:w-1/2 pr-5 mt-5"
          >
            <div>
              <label class="font-bold">Purchased At:</label>
              <p>
                {{
                  moment(currentContents[0].mySubscriptionDetails[0].purchasedAt).format("DD/MM/YYYY")
                }}
              </p>
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            class="w-full sm:w-1/2 pl-5 mt-5"
          >
            <div>
              <label class="font-bold">Start Date:</label>
              <p>
                {{ moment(currentContents[0].startDate).format("DD/MM/YYYY") }}
              </p>
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            class="w-full sm:w-1/2 pr-5 mt-5"
          >
            <div>
              <label class="font-bold">End Date:</label>
              <p>
                {{ currentContents[0].endDate ? moment(currentContents[0].endDate).format("DD/MM/YYYY") : '-' }}
              </p>
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            class="w-full sm:w-1/2 pl-5 mt-5"
          >
            <div>
              <label class="font-bold">Billing Started At:</label>
              <p>
                {{
                  moment(billingStartedAt).format(
                    "DD/MM/YYYY"
                  )
                }}
              </p>
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            class="w-full sm:w-1/2 pr-5 mt-5"
          >
            <div>
              <label class="font-bold">Number of Centre:</label>
              <p>{{ totalCenters }}</p>
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            class="w-full sm:w-1/2 pl-5 mt-5"
          >
            <div>
              <label class="font-bold">Number of Children:</label>
              <p>{{ totalChild }}</p>
            </div>
          </vs-col>
        </vs-row>
      </vx-card>

      <!--      <vx-card class="mb-base directors-card">-->
      <!--        <div v-if="renews.length > 0">-->
      <!--          <vs-table max-items="5" pagination :data="renews">-->
      <!--            <div slot="header" class="w-full">-->
      <!--              <div class="mb-8">-->
      <!--                <h2 class="mb-0">Renew Details</h2>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <template slot="thead">-->
      <!--              <vs-th>Renewed Date</vs-th>-->
      <!--              <vs-th>End Date</vs-th>-->
      <!--              <vs-th>Price</vs-th>-->
      <!--            </template>-->
      <!--            <template slot-scope="{ data }">-->
      <!--              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">-->
      <!--                <vs-td :data="data[indextr].renewedDate">{{-->
      <!--                    moment(data[indextr].renewedDate).format("DD/MM/YYYY")-->
      <!--                  }}-->
      <!--                </vs-td>-->
      <!--                <vs-td :data="data[indextr].endDate">{{-->
      <!--                    moment(data[indextr].endDate).format("DD/MM/YYYY")-->
      <!--                  }}-->
      <!--                </vs-td>-->
      <!--                <vs-td :data="data[indextr].price"-->
      <!--                >$ {{ data[indextr].price }}/child/month-->
      <!--                </vs-td-->
      <!--                >-->
      <!--              </vs-tr>-->
      <!--            </template>-->
      <!--          </vs-table>-->
      <!--        </div>-->
      <!--        <div class="px-10" v-else>-->
      <!--          <h6>No renew details available</h6>-->
      <!--        </div>-->
      <!--      </vx-card>-->
    </div>
    <div v-else>
      <vx-card class="mb-base">
        <div class="mb-8">
          <h2 class="mb-0">No Any Current Subscription Available</h2>
        </div>
      </vx-card>
    </div>
    <div>
      <vx-card class="mb-base">
        <div class="vx-row mb-5 flex">
          <div class="vx-col md:w-1/2 w-full">
            <h2 class="mb-0">Pending Upgrade Package</h2>
          </div>
        </div>
        <div v-if="pendingPackage"
        >
          <vs-row
            vs-align="flex-start"
            vs-type="flex"
            vs-justify="left"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              class="w-full sm:w-1/2 pr-5 mt-5"
            >
              <div>
                <label class="font-bold">Subscription Name:</label>
                <p>
                  {{
                    pendingPackage.subscriptionPackageDetails.name
                      | capitalize
                  }}
                </p>
              </div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              class="w-full sm:w-1/2 pl-5 mt-5"
            >
              <div>
                <label class="font-bold">Price:</label>
                <p>${{ pendingPackage.subscriptionPackageDetails.price }} /child/month</p>
              </div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="left"
              vs-align="left"
              class="w-full sm:w-1/2 pr-5 mt-5"
            >
              <div>
                <label class="font-bold">Start Date:</label>
                <p v-if="pendingPackage.startedAt">
                  {{ moment(pendingPackage.startedAt).format('DD/MM/YYYY') }}
                </p>
                <p v-else>
                  {{ moment().add(1, 'months').format('DD/MM/YYYY') }}
                </p>
              </div>
            </vs-col>
          </vs-row>
        </div>
        <div v-else>
          No pending upgrade package.
        </div>
      </vx-card>
    </div>


    <!--    <vx-card class="mb-base directors-card">
          <div v-if="pastContents.length > 0">
            <vs-table max-items="5" pagination :data="pastContents">
              <div slot="header" class="w-full">
                <div class="mb-8">
                  <h2 class="mb-0">Past Subscription Details</h2>
                </div>
              </div>
              <template slot="thead">
                <vs-th>Package Name</vs-th>
                <vs-th>Price</vs-th>
                <vs-th>Purchased At</vs-th>
                <vs-th>Cancelled At</vs-th>
                <vs-th>Number of Renews</vs-th>
                <vs-th>Actions</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].subscriptionPackageDetails[0].name">{{
                    data[indextr].subscriptionPackageDetails[0].name | capitalize
                  }}</vs-td>
                  <vs-td :data="data[indextr].price"
                    >$ {{ data[indextr].price }}/child/month</vs-td
                  >
                  <vs-td :data="data[indextr].purchasedAt">{{
                    moment(data[indextr].purchasedAt).format("DD/MM/YYYY")
                  }}</vs-td>
                  <vs-td :data="data[indextr].candelledAt">{{
                    moment(data[indextr].candelledAt).format("DD/MM/YYYY")
                  }}</vs-td>
                  <vs-td :data="data[indextr].renews.length">{{
                    data[indextr].renews.length
                  }}</vs-td>
                  <vs-td :data="data[indextr]._id" class="text-center">
                    <a href="#" @click.stop="viewDetailHandler(data[indextr]._id)">
                      <feather-icon
                        icon="EyeIcon"
                        svgClasses="w-5 h-5 hover:text-primary stroke-current"
                      />
                    </a>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
          <div class="px-10" v-else>
            <h6>No past subscription details available</h6>
          </div>
        </vx-card>-->
  </div>
</template>

<script>
import vSelect from "vue-select";
import {mapActions} from "vuex";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import _ from "lodash";

export default {
  components: {
    "v-select": vSelect
  },
  data() {
    return {
      isMounted: false,
      contents: [],
      currentContents: [],
      renews: [],
      pastContents: [],
      subscriptionId: "",
      pendingPackage: '',
      totalChild:0,
      totalCenters: 0,
      billingStartedAt:0

    };
  },
  methods: {
    ...mapActions("setting", [
      "fetchDirectorCurrentSubscription",
      "fetchDirectorPastSubscription",
      "cancelSubscription",
      "fetchDirectorPendingUpgradeSubscription"
    ]),
    moment(date) {
      return moment(date);
    },
    getFirstDayOfNextMonth(date) {
      const newDate = new Date(date);
      return new Date(newDate.getFullYear(), newDate.getMonth() + 1, 1);
    },
    getCurrentSubscription() {
      this.fetchDirectorCurrentSubscription().then(res => {
        this.currentContents = res.data.data;
        this.subscriptionId = this.currentContents[0]._id;
        this.billingStartedAt = this.getFirstDayOfNextMonth(this.currentContents[0].mySubscriptionDetails[0].purchasedAt)
        let totalChild = 0;
        if( this.currentContents.length > 0){

          this.totalCenters = this.currentContents[0].learningCenters.length;
          _.forEach(this.currentContents[0].learningCenters, function(center) {
            totalChild = totalChild + center.childrens.length;
          });
          this.totalChild = totalChild;
        }



      });
    },
    getPastSubscription() {
      this.fetchDirectorPastSubscription().then(res => {
        this.pastContents = res.data.data;
      });
    },
    async getPendingPackage() {
      await this.fetchDirectorPendingUpgradeSubscription().then((res) => {
        this.pendingPackage = res.data.data;
      }).catch(err => console.log(err));
    },
    /*getSubscriptionList() {
        this.fetchDirectorSubscription(this.dataTableParams).then(res => {
          this.contents = res.data.data.docs;
        });
      },*/
    viewDetailHandler(id) {
      this.$router.push({
        name: "past-subscription-details",
        params: {id: id}
      });
    },
    cancelHandler() {
      Swal.fire({
        title: "Are you sure you want to cancel this subscription?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, cancel it!"
      }).then(result => {
        if (result.value) {
          this.acceptDeleteAlert(this.subscriptionId);
        }
      });
    },
    acceptDeleteAlert(id) {
      this.$vs.loading();
      this.cancelSubscription(id)
        .then(async res => {
          if (res.status === 200) {
            this.currentContents = "";
            await this.getCurrentSubscription();
            await this.getPastSubscription();
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Success",
              text: "Subscription cancelled successfully.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success"
            });
          }
        })
        .catch(err => {
          this.$vs.loading.close();
          // console.error(err)
        });
    },
    editSubscriptionHandler() {
      this.$router.push('/director/upgrade-package');
    },
  },
  created() {
    this.getCurrentSubscription();
    this.getPastSubscription();
    this.getPendingPackage();
  }
};
</script>
